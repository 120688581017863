<template>
    <v-row>
        <v-col
            cols="12"
            class="d-lg-none"
        >
            <FiltersResponsive />
        </v-col>
        <v-col
            cols="12"
            lg="9"
            xl="10"
        >
            <FiltersTags />
            <CatalogueMode
                class="my-8"
                :keepCurrentState="keepCurrentState"
                @update:order-by="getProducts"
            />
            <InfiniteScroll @load-more="loadMore()">
                <ProductsList :mode="getProductsViewMode" />
            </InfiniteScroll>
        </v-col>
        <v-col
            lg="3"
            xl="2"
            class="d-none d-lg-block"
        >
            <Filters />
        </v-col>
    </v-row>
</template>

<script setup>
import { onMounted, watch, nextTick } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';

import { useNotificationStore } from '@/store/common/notification';
import { useLoadingStore } from '@/store/common/loading';
import InfiniteScroll from '@/components/Common/InfiniteScroll.vue';

import { useProductsStore } from '@/store/products';

import Filters from '@/components/Catalogue/Filters.vue';
import FiltersTags from '@/components/Catalogue/FiltersTags.vue';
import ProductsList from '@/components/Catalogue/ProductsList.vue';
import CatalogueMode from '@/components/Catalogue/CatalogueMode.vue';
import FiltersResponsive from '@/components/Catalogue/FiltersResponsive.vue';

// External
const route = useRoute();
const { t } = useI18n();

// Global
const { setLoading } = useLoadingStore();
const notificationStore = useNotificationStore();

// Products
const productsStore = useProductsStore();
const { getProducts, loadMore, getProductsViewMode } = useProductList({ productsStore });

const keepCurrentState = () => {
    return Boolean(window.history.state?.forward?.startsWith('/pdp/') && productsStore.products?.length);
};

// Lifecycle hooks
onMounted(async () => {
    if (keepCurrentState()) {
        const routeHistoryState = window.history.state;
        // Go to the previous scroll position
        if (routeHistoryState?.scroll?.top) {
            await nextTick();
            // Delay the scroll to avoid to scroll before the products are rendered
            setTimeout(() => {
                window.scrollTo(0, routeHistoryState.scroll.top);
            }, 300);
        }

        return; // Do not load products again
    }

    productsStore.resetPagination();
    getProducts();
});

watch(
    () => route.params.seasonId,
    () => {
        if (route.name !== 'catalogue') {
            return; // Do not load products when moving to another page
        }

        productsStore.resetPagination();
        getProducts();
    }
);

/**
 * Reusable functions specific to this component
 */
function useProductList({ productsStore }) {
    const { search, pagination } = storeToRefs(productsStore);

    watch(
        [productsStore.filtersSelected, search],
        () => {
            productsStore.resetPagination();

            getProducts();
        },
        { deep: true }
    );

    const getProducts = async () => {
        setLoading(true);
        try {
            const season = route.params.seasonId;
            await productsStore.loadProducts([season]);

            setLoading(false);
        } catch (error) {
            setLoading(false);
            notificationStore.error({ message: t('common.notification.read.error') });
        }
    };

    const loadMore = () => {
        const { offset, total } = pagination.value;

        if (total === null || total - offset <= 0) {
            return;
        }

        getProducts();
    };

    const { getMode: getProductsViewMode } = storeToRefs(productsStore);

    return {
        getProducts,
        loadMore,
        getProductsViewMode
    };
}
</script>
